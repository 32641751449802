import React, { useEffect, useState } from "react";
import {
  View,
  ScrollView,
  useWindowDimensions,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import colors from "../assets/colors/colors";
import Cart from "../components/Cart";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { LatoRegular } from "../components/Text";
import { useAuth } from "../contexts/AuthContext";
import { getDeviceType } from "../tools/Interface";
import { useLocation, useNavigate } from "react-router";
import { RadioSelection, SelectionDropDown } from "../components/Inputs";
import { openWhatsapp } from "../tools/tools";
import { contactDecider } from "../tools/decider";
import Buttons from "../components/Buttons";
import { api } from "../utils/api";
import CheckoutTracker from "../components/CheckoutTracker";

export default function CheckoutShipmentScreen() {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  const { user } = useAuth();
  const { address = {} } = user;
  const { recipient_name, complete_address, recipient_number } = address;
  const navigate = useNavigate();
  const { state } = useLocation();
  const { courier } = state || {};
  const { logistic: logisticParams = {} } = courier || {};

  const [selected, setSelected] = useState({});
  const [radioSelected, setRadioSelected] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState([]);
  const [logistic, setLogistic] = useState(logisticParams);

  const style = StyleSheet.create({
    sectionContainer: {
      width: isTablet ? containerWidth : (2 * containerWidth) / 3 - 40,
    },
    sectionHeader: {
      fontSize: 20,
      lineHeight: 24,
      color: colors.grey33,
    },
  });

  const radioArr = [
    {
      label: "Standard",
      additional: [
        "Jabodetabek - Estimated Time: 2-4 days",
        "Non-Jabodetabek - Estimated Time: 3-7 days",
      ],
      value: [4, 5],
    },
    {
      label: "Same-Day",
      additional: [
        "Jabodetabek - Estimated Time: 1-2 days",
        "Non-Jabodetabek - Estimated Time: 2 days",
      ],
      value: [2, 3, 999],
    },
    {
      label: "Instant",
      additional: ["Jabodetabek - Estimated Time: 1 day"],
      value: [1],
    },
  ];

  const [option, setOption] = useState(radioArr);

  const getItems = async () => {
    try {
      setLoading(true);
      const res = await api.get("/api/carts");
      const data = res?.data?.data;
      const { products, courier_pricing } = data;
      const { logistic: savedLogistic, rate } = courier_pricing || {};

      const message = `Hey, I'm trying to order ${
        products.length > 1 ? "these items" : "item"
      } with SKU ${products.length > 1 ? "numbers" : "number"}:%0a${products
        .map(
          ({ sku, name, inventory }, i) =>
            `${
              i + 1
            }. ${name} (${sku}): https://www.belleandkate.com/products/${
              inventory?.id
            }`
        )
        .join(
          "%0a"
        )} %0a%0aBut it looks like there are no shipments available. Can you help me out or suggest alternatives?%0a %0aThanks!`;

      setMessage(message);

      if (rate?.id) {
        setRadioSelected(
          radioArr.filter(({ value }) => value.includes(rate?.id))[0]?.value
        );
        setLogistic(savedLogistic);
      }
    } catch (err) {
      console.log("err:", err);
      // showToast(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getItems();
  }, []);

  return (
    <View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ height: "100vh" }}
      >
        <Navbar backgroundColor={colors.whiteFF} isCheckout />
        <CheckoutTracker position="shipment" />
        <View
          style={{
            flexDirection: isTablet ? "column-reverse" : "row",
            justifyContent: "space-between",
            paddingHorizontal: gap,
            paddingBottom: 200,
          }}
        >
          <View style={style.sectionContainer}>
            <View
              style={{
                paddingBottom: 32,
                borderBottomColor: colors.greyE6,
                borderBottomWidth: 1,
                marginBottom: 32,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 16,
                }}
              >
                <LatoRegular style={style.sectionHeader}>Ship to</LatoRegular>
                <TouchableOpacity
                  onPress={() =>
                    navigate("/checkout/address", {
                      state: {
                        courier: courier || {},
                        fromShipment: true,
                        radioSelected,
                      },
                    })
                  }
                  style={{
                    borderBottomColor: colors.grey33,
                    borderBottomWidth: 1,
                    paddingBottom: 2,
                    paddingHorizontal: 4,
                  }}
                >
                  <LatoRegular
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey33,
                    }}
                  >
                    Edit
                  </LatoRegular>
                </TouchableOpacity>
              </View>
              <LatoRegular
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  marginBottom: 8,
                  color: colors.grey33,
                }}
              >
                {recipient_name}
              </LatoRegular>
              <LatoRegular
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  marginBottom: 16,
                  color: colors.grey33,
                }}
              >
                {`+62${recipient_number}`}
              </LatoRegular>
              <LatoRegular
                style={{ fontSize: 14, lineHeight: 20, color: colors.grey33 }}
              >
                {complete_address}
              </LatoRegular>
            </View>
            <LatoRegular style={{ ...style.sectionHeader, marginBottom: 16 }}>
              Shipping Method
            </LatoRegular>
            {Boolean(
              option.filter(({ hide }) => hide)?.length !== radioArr?.length
            ) ? (
              <View
                style={{
                  flexDirection: isPhone ? "column" : "row",
                  flexWrap: 'wrap',
                  justifyContent:
                    option.filter(({ hide }) => hide)?.length < 3
                      ? "flex-start"
                      : "space-between",
                  marginBottom: isPhone ? 16 : 32,
                  marginTop: 16,
                }}
              >
                <RadioSelection
                  selection={option}
                  selected={radioSelected}
                  setSelected={setRadioSelected}
                />
              </View>
            ) : loading ? null : (
              <LatoRegular
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  color: colors.grey33,
                  marginVertical: 24,
                }}
              >
                We don’t currently deliver to outside Jabodetabek. Please chat
                our client advisor
              </LatoRegular>
            )}
            <SelectionDropDown
              name={"courier"}
              required
              values={selected}
              setValue={setSelected}
              label={"Shipping Methods"}
              noLabel
              initialID={logistic?.id}
              filterString={radioSelected}
              disabled={!radioSelected}
              radioArr={option}
              setRadioArr={setOption}
              hide={Boolean(
                option.filter(({ hide }) => hide)?.length === radioArr?.length
              )}
              setMainLoading={setLoading}
            />
            <View
              style={{
                flexDirection: isPhone ? "column-reverse" : "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <TouchableOpacity
                onPress={() => navigate(-1)}
                style={{
                  borderBottomColor: colors.grey33,
                  borderBottomWidth: 1,
                  paddingBottom: 2,
                  paddingHorizontal: 4,
                }}
              >
                <LatoRegular
                  style={{
                    fontSize: 14,
                    lineHeight: 20,
                    color: colors.grey33,
                  }}
                >
                  {courier?.logistic
                    ? "Return to Payment"
                    : "Return to Address"}
                </LatoRegular>
              </TouchableOpacity>
              <Buttons
                onPress={() =>
                  option.filter(({ hide }) => hide)?.length !== radioArr?.length
                    ? navigate("/checkout/payment", {
                        state: {
                          courier: selected,
                          radioSelected: option.filter(
                            ({ value }) => value === radioSelected
                          )[0],
                        },
                      })
                    : openWhatsapp(message, contactDecider("OtherBags"))
                }
                disabled={
                  option.filter(({ hide }) => hide)?.length !== radioArr?.length
                    ? (!selected?.logistic && option?.length) || loading
                    : false
                }
                loading={loading}
                isBlack
                width={isPhone ? containerWidth : 250}
                label={
                  option.filter(({ hide }) => hide)?.length !== radioArr?.length
                    ? courier?.logistic
                      ? "Save Changes"
                      : "Continue to Payment"
                    : "Contact us"
                }
              />
            </View>
          </View>
          <View
            style={{
              marginTop: isTablet ? 32 : -90,
              marginBottom: isTablet ? 48 : 0,
              paddingBottom: isTablet ? 48 : 0,
              borderBottomColor: colors.greyE6,
              marginHorizontal: isTablet ? -gap : 0,
              paddingHorizontal: isTablet ? gap : 0,
              borderBottomWidth: isTablet ? 1 : 0,
              width: isTablet ? width : containerWidth / 3 - 40,
            }}
          >
            <LatoRegular style={style.sectionHeader}>Order Summary</LatoRegular>
            <Cart
              isCheckout={isTablet ? containerWidth : containerWidth / 3 - 40}
            />
          </View>
        </View>
        <Footer />
      </ScrollView>
    </View>
  );
}
