import React from "react";
import {
  Image,
  TouchableOpacity,
  Easing,
  View,
  useWindowDimensions,
} from "react-native";
import colors from "../../assets/colors/colors";
import { useLocation, useParams } from "react-router";
import { getDeviceType } from "../../tools/Interface";
import { LatoBold, LatoRegular } from "../Text";
import { AiOutlineArrowDown } from "react-icons/ai";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import { Text } from "react-native-web";
import { Link } from "react-router-dom";
import { LinksFromCDN } from "../../constants/cdnLinks";

function CatalogHeader({
  total = 0,
  setShownSpecialFilter = () => {},
  setShownFilter = () => {},
  shownFilter = false,
  shownSpecialFilter = false,
  specialFilter = {},
  animate = () => {},
  selected = [],
}) {
  const { search, pathname } = useLocation();
  const queryParams = new URLSearchParams(search);
  const query = queryParams.get('query');
  const { id } = useParams();
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  const designerArr = ["Hermes", "Chanel", "Dior", "Louis Vuitton"];
  const pathArr = pathname.split("/")
  const sectionIsDesigner = pathArr[2]?.startsWith("designer") ?? false
  const sectionIsCategory = pathArr[2]?.startsWith("category") ?? false
  const brand = decodeURIComponent(
    (pathArr[3] ?? "")
      .split("?")[0] ?? "" //clear potential query params
  )
  const brand_cover_map = {
    "Hermes": LinksFromCDN.hermesCoverImage,
    "Dior": LinksFromCDN.diorCoverImage,
    "Chanel": LinksFromCDN.chanelCoverImage,
    "Louis Vuitton": LinksFromCDN.louisVuittonCoverImage,
  }
  const isDesignerValid = designerArr.includes(brand)

  return (
    <View>
      <View
        style={{
          alignItems: "center",
          marginTop: 48,
          borderBottomColor: colors.greyE6,
          borderBottomWidth: Boolean(
            query || (id && sectionIsCategory)
          )
            ? 1
            : 0,
          marginHorizontal: gap,
          marginBottom: 28,
        }}
      >
        {Boolean(
          query || (id && sectionIsCategory) || !id
        ) && (
          <LatoBold
            style={{
              fontSize: 24,
              lineHeight: 32,
              textTransform: "uppercase",
              marginBottom: 16,
            }}
          >
            {Boolean(
              query || (id && sectionIsCategory)
            )
              ? `"${query || brand}"`
              : "Shop Now"}
          </LatoBold>
        )}
      </View>
      {Boolean(id && sectionIsDesigner && isDesignerValid) && (
        <View
          style={{
            marginBottom: isPhone ? 0 : 48,
            marginHorizontal: gap,
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: colors.black00,
          }}
        >
          <View
            style={{
              width: isPhone ? containerWidth : containerWidth / 2,
            }}
          >
            <Image
              source={brand_cover_map[brand]}
              style={{
                height: isPhone ? (((containerWidth / 2) * 475) / 730) : 500,
                width: isPhone ? containerWidth : containerWidth / 2,
              }}
              resizeMode={"cover"}
            />
          </View>
          {Boolean(isPhone) ? (
            <View
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                // paddingBottom: 54,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: colors.transparentBlack00,
                transition: "0.5s",
              }}
            >
              <LatoRegular
                style={{
                  color: colors.whiteFF,
                  fontSize: 36,
                  lineHeight: 48,
                  textShadowColor: colors.transparentBlack00,
                  textShadowOffset: { width: 1, height: 1 },
                  textShadowRadius: 1,
                  textTransform: "uppercase",
                }}
              >
                {brand || ""}
              </LatoRegular>
            </View>
          ) : (
            <View
              style={{
                width: containerWidth / 2,
                paddingHorizontal: isTablet ? 16 : 24,
              }}
            >
              <LatoRegular
                style={{
                  color: colors.whiteFF,
                  fontSize: isTablet ? 24 : 32,
                  lineHeight: isTablet ? 32 : 40,
                  marginBottom: isTablet ? 32 : 42,
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                {brand || ""}
              </LatoRegular>
              {Boolean(!isPhone) && (
                <Text
                  style={{
                    color: colors.whiteFF,
                    fontSize: isTablet ? 14 : 16,
                    lineHeight: isTablet ? 20 : 24,
                    textAlign: "left",
                    alignSelf: "center",
                    fontFamily: "Lato-Regular",
                  }}
                >
                  {
                    brand === "Hermes" &&
                    <>
                      <p className="lato-regular mb-4">Discover the world of Hermès at Belle & Kate, where luxury and quality come together. Known for its legendary Birkin and Kelly bags, Hermès is an iconic brand that embodies elegance, exclusivity, and exquisite craftsmanship.</p>
                      <p className="lato-regular mb-4">Founded in 1837, Hermès is synonymous with meticulously crafted leather goods, fashion, and accessories that stand the test of time. Each Hermès piece, whether a handbag, silk scarf, or accessory,reflects French sophistication and dedication to artistry.</p>
                      <p className="lato-regular mb-4">Explore our Hermes collections:{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Hermes 24/24")}`}>Hermes 24/24,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Hermes Birkin")}`}>Hermes Birkin,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Hermes Bolide")}`}>Hermes Bolide,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Hermes Constance")}`}>Hermes Constance,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Hermes Evelyne")}`}>Hermes Evelyne,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Hermes Garden Party")}`}>Hermes Garden Party,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Hermes Geta")}`}>Hermes Geta,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Hermes In The Loop")}`}>Hermes In-the-Loop,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Hermes Kelly")}`}>Hermes Kelly,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Hermes Lindy")}`}>Hermes Lindy,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Hermes Picotin")}`}>Hermes Picotin,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Hermes Roulis")}`}>Hermes Roulis,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Hermes Toolbox")}`}>Hermes Toolbox,</Link>{" "}
                        and{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Hermes Vide-Poches")}`}>Hermes Vide-Poches.</Link>
                      </p>
                    </>
                  }
                  {
                    brand === "Chanel" &&
                    <>
                      <p className="lato-regular mb-4">Step into the world of Chanel at Belle & Kate, where classic style and modern luxury meet. Chanel, a name synonymous with elegance and innovation, has been a fashion icon since its founding by Coco Chanel in 1910.</p>
                      <p className="lato-regular mb-4">Known for its signature tweed jackets, quilted handbags, and the legendary Chanel No. 5 fragrance, Chanel embodies sophistication with a touch of boldness. Each Chanel creation, from handbags to ready-to-wear and accessories, is crafted with precision, making it a staple for those who value timeless style.</p>
                      <p className="lato-regular mb-4">Explore our Chanel collections:{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Chanel 19")}`}>Chanel 19,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Chanel 22")}`}>Chanel 22,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Chanel Backpack")}`}>Chanel Backpack,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Chanel Boy")}`}>Chanel Boy,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Chanel Classic Flap Bag")}`}>Chanel Classic Flap Bag,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Chanel Gabrielle")}`}>Chanel Gabrielle,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Chanel Top Handle")}`}>Chanel Top Handle,</Link>{" "}
                        and{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Chanel Vanity")}`}>Chanel Vanity.</Link>
                      </p>
                    </>
                  }
                  {
                    brand === "Louis Vuitton" &&
                    <>
                      <p className="lato-regular mb-4">Elevate your wardrobe with Louis Vuitton at Belle&Kate, home to some of the most coveted luxury pieces in the world. Founded in 1854, Louis Vuitton has long been a symbol of timeless elegance and exceptional craftsmanship. Known for its signature monogram and legendary handbags like the Speedy and Neverfull, Louis Vuitton combines rich heritage with contemporary design.</p>
                      <p className="lato-regular mb-4">From accessories to ready-to-wear collections, each piece reflects the brand’s commitment to quality and style. Shop Louis Vuitton at Belle & Kate and discover the perfect blend of sophistication, luxury, and iconic fashion.</p>
                      <p className="lato-regular mb-4">Explore our Louis Vuitton collections:{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Louis Vuitton Alma")}`}>Louis Vuitton Alma,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Louis Vuitton Capucines")}`}>Louis Vuitton Capucines,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Louis Vuitton Chapeau")}`}>Louis Vuitton Chapeau,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Louis Vuitton Felicie")}`}>Louis Vuitton Felicie,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Louis Vuitton Metis")}`}>Louis Vuitton Metis,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Louis Vuitton Neverfull")}`}>Louis Vuitton Neverfull,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Louis Vuitton Pochette")}`}>Louis Vuitton Pochette,</Link>{" "}
                        and{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Speedy Bandouliere")}`}>Louis Vuitton Speedy Bandouliere.</Link>
                      </p>
                    </>
                  }
                  {
                    brand === "Dior" &&
                    <>
                      <p className="lato-regular mb-4">Step into the world of Dior at Belle & Kate, where sophistication and exceptional craftsmanship come together. Since its inception in 1946, Christian Dior has redefined the fashion industry with its luxurious designs, including the legendary Lady Dior bag and its signature haute couture collections.</p>
                      <p className="lato-regular mb-4">Known for its refined elegance and bold innovation, Dior continues to create pieces that are as timeless as they are stylish. Whether you're looking for a statement bag or an elegant accessory, Dior at Belle & Kate offers the perfect blend of classic charm and modern luxury to elevate any wardrobe.</p>
                      <p className="lato-regular mb-4">Explore our Dior collections:{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Dior Book Tote")}`}>Dior Book Tote,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Dior Caro")}`}>Dior Caro,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Lady Dior")}`}>Dior Lady Dior,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("D-Joy")}`}>Dior Lady D-Joy,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Dior Montaigne East-West")}`}>Dior Montaigne East-West,</Link>{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Dior Saddle")}`}>Dior Saddle,</Link>{" "}
                        and{" "}
                        <Link className="underline hover:no-underline" to={`/products?query=${encodeURIComponent("Dior Vanity")}`}>Dior Vanity.</Link>
                      </p>
                    </>
                  }
                </Text>
              )}
            </View>
          )}
        </View>
      )}
      {/* <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}> */}
      {Boolean(id && sectionIsDesigner && brand && !isDesignerValid) && (
        <View
          style={{
            backgroundColor: colors.whiteFF,
            justifyContent: "center",
            alignItems: "center",
            borderBottomColor: colors.greyE6,
            borderBottomWidth: 1,
            marginHorizontal: gap,
            marginBottom: 28
          }}
        >
          <LatoBold
            style={{
              fontSize: isPhone ? 16 : 24,
              lineHeight: isPhone ? 24 : 32,
              color: colors.black,
              textAlign: "center",
              textTransform: "uppercase",
              marginBottom: 16
            }}
          >
            {brand}
          </LatoBold>
        </View>
      )}
    {/* </View> */}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingVertical: 12,
          paddingHorizontal: gap,
          width,
        }}
      >
        {Boolean(!isPhone) && <LatoRegular>{`${total} PRODUCTS`}</LatoRegular>}
        <View
          style={{
            flexDirection: "row",
            alignItems: isPhone ? "flex-start" : "center",
            justifyContent: isPhone ? "space-between" : "flex-end",
            width: isPhone ? containerWidth : "auto",
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setShownFilter(!shownFilter);
              animate(Easing.ease);
            }}
            style={{
              paddingVertical: selected.length ? 5 : 8.5,
              paddingHorizontal: 20,
              borderWidth: 1,
              borderColor: colors.grey90,
              borderRadius: 4,
              marginRight: 16,
            }}
          >
            <View
              style={{
                flexDirection:
                  isPhone || selected.length ? "row" : "row-reverse",
                alignItems: "center",
              }}
            >
              {Boolean(!selected.length) ? (
                <HiOutlineAdjustmentsHorizontal size={16} />
              ) : (
                Boolean(isPhone && selected.length) && (
                  <View
                    style={{
                      borderRadius: 1000,
                      padding: 4,
                      width: 24,
                      height: 24,
                      backgroundColor: colors.grey33,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <LatoRegular
                      style={{
                        fontSize: 12,
                        lineHeight: 16,
                        color: colors.whiteFF,
                      }}
                    >
                      {selected.length}
                    </LatoRegular>
                  </View>
                )
              )}
              <LatoRegular
                style={{
                  fontSize: 12,
                  lineHeight: 16,
                  marginLeft: !isPhone ? 0 : 8,
                  marginRight: !isPhone && !selected.length ? 8 : 0,
                }}
              >
                {!isPhone && selected.length ? "FILTER APPLIED" : "FILTER"}
              </LatoRegular>
              {Boolean(!isPhone && selected.length) && (
                <View
                  style={{
                    borderRadius: 1000,
                    padding: 4,
                    width: 24,
                    height: 24,
                    backgroundColor: colors.grey33,
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: 8,
                  }}
                >
                  <LatoRegular
                    style={{
                      fontSize: 12,
                      lineHeight: 16,
                      color: colors.whiteFF,
                    }}
                  >
                    {selected.length}
                  </LatoRegular>
                </View>
              )}
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => setShownSpecialFilter(!shownSpecialFilter)}
            style={{
              paddingVertical: 8.5,
              paddingHorizontal: 20,
              borderWidth: 1,
              borderColor: colors.grey90,
              borderRadius: 4,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <LatoRegular
                style={{
                  marginRight: 6,
                  fontSize: 12,
                  lineHeight: 16,
                  textTransform: "uppercase",
                }}
              >
                {specialFilter.title}
              </LatoRegular>
              <AiOutlineArrowDown width={14} height={14} />
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}

export default CatalogHeader;
