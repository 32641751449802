import React, { useContext } from "react";
import { TouchableOpacity, View, useWindowDimensions } from "react-native";
import { getDeviceType } from "../tools/Interface";
import colors from "../assets/colors/colors";
import { LatoBold, LatoRegular } from "./Text";
import Shimmer from "./Shimmer";
import { formatCurrency } from "../tools/formatter";
import { CgShoppingBag } from "react-icons/cg";
import { useNavigate } from "react-router";
import Buttons from "./Buttons";
import ToasterContext from "../contexts/ToastContext";
import { api } from "../utils/api";

function CheckoutProduct({
  isCheckout = false,
  loading = false,
  customLoading = false,
  data = {},
  getItem = async () => {},
  getItems = async () => {},
  getData = async () => {},
}) {
  const { width, height } = useWindowDimensions();
  const navigate = useNavigate();
  const { isPhone } = getDeviceType({ width, height });

  const { showToast, showSuccessToast } = useContext(ToasterContext);

  const { calculator, voucher_details = {} } = data;
  const { code, discount_type, discount_value } = voucher_details;

  const detailArr = [
    {
      title: "Shipping",
      value: calculator?.discounted_delivery_fee
        ? `IDR ${formatCurrency(calculator?.discounted_delivery_fee)}`
        : "Calculated at next step",
    },
    {
      title: "Insurance fee",
      value: calculator?.insurance_fee
        ? calculator
          ? `IDR ${formatCurrency(calculator?.insurance_fee)}`
          : "Calculated at next step"
        : "",
    },
    {
      title: "Administration fee",
      value: calculator?.payment_fee
        ? calculator
          ? `IDR ${formatCurrency(calculator?.payment_fee)}`
          : "Calculated at next step"
        : "",
    },
  ];

  const handleRemoveCode = async () => {
    try {
      await api.post("api/carts/input_voucher", { voucher_code: null });
      showSuccessToast("Code has been removed");

      await getItems();
      await getData();
      await getItem();
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
    }
  };

  return (
    <View
      style={{
        position: isCheckout ? "relative" : "absolute",
        bottom: 24,
        width: isCheckout ? "100%" : isPhone ? width : 450,
        marginTop: isCheckout ? 24 : 0,
        paddingRight: isCheckout ? 0 : 48,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingVertical: 24,
          borderTopColor: colors.greyE6,
          borderTopWidth: isCheckout ? 0 : 1,
          marginHorizontal: -24,
          paddingHorizontal: 24,
        }}
      >
        <LatoRegular
          style={{ color: isCheckout ? colors.grey6C : colors.grey33 }}
        >
          Subtotal
        </LatoRegular>
        {loading ? (
          <Shimmer
            autoRun={true}
            visible={false}
            width={"7.5vw"}
            height={16}
            hasBorder
          />
        ) : (
          <LatoBold>{`IDR ${formatCurrency(
            calculator?.discounted_subtotal
          )}`}</LatoBold>
        )}
      </View>
      {Boolean(isCheckout) && (
        <View>
          {Boolean(code) && (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: 24,
                borderTopColor: colors.greyE6,
                borderTopWidth: isCheckout ? 0 : 1,
                marginHorizontal: -24,
                paddingHorizontal: 24,
              }}
            >
              <View>
                <LatoRegular style={{ color: colors.grey6C, marginBottom: 10 }}>
                  Promo Code Applied
                </LatoRegular>
                <LatoRegular style={{ color: colors.grey6C }}>
                  {`(${code} - Price off ${
                    discount_type === "percentage"
                      ? `${discount_value}%`
                      : `IDR ${formatCurrency(discount_value)}`
                  })`}
                </LatoRegular>
              </View>
              {loading ? (
                <Shimmer
                  autoRun={true}
                  visible={false}
                  width={"7.5vw"}
                  height={16}
                  hasBorder
                />
              ) : (
                <View>
                  <LatoBold
                    style={{
                      marginBottom: 10,
                      textAlign: "right",
                    }}
                  >{`-IDR ${formatCurrency(
                    calculator?.discount_amount
                  )}`}</LatoBold>
                  <Buttons
                    onPress={handleRemoveCode}
                    label={"Remove Code"}
                    noBorder
                    height={16}
                    textStyle={{
                      fontFamily: "lato-regular",
                      textAlign: "right",
                    }}
                    containerStyle={{ marginLeft: 6 }}
                    color={colors.grey6C}
                  />
                </View>
              )}
            </View>
          )}
          {detailArr
            .filter(({ value }) => value)
            .map(({ value, title }, idx) => (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginHorizontal: -24,
                  paddingHorizontal: 24,
                  paddingBottom:
                    calculator?.insurance_fee || calculator?.payment_fee
                      ? 24
                      : 0,
                }}
              >
                <LatoRegular style={{ color: colors.grey6C }}>
                  {title}
                </LatoRegular>
                {loading || customLoading ? (
                  <Shimmer
                    autoRun={true}
                    visible={false}
                    width={"7.5vw"}
                    height={16}
                    hasBorder
                  />
                ) : (
                  <LatoBold
                    style={{
                      color: colors.grey33,
                    }}
                  >
                    {value}
                  </LatoBold>
                )}
              </View>
            ))}
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: 24,
              paddingTop: 24,
              borderTopWidth: 1,
              borderTopColor: colors.greyE6,
              width: "100%",
            }}
          >
            <LatoBold style={{ color: colors.grey33 }}>Total</LatoBold>
            {loading || customLoading ? (
              <Shimmer
                autoRun={true}
                visible={false}
                width={"7.5vw"}
                height={16}
                hasBorder
              />
            ) : (
              <LatoBold
                style={{
                  color: colors.grey33,
                }}
              >
                {calculator
                  ? `IDR ${formatCurrency(calculator?.final_payment)}`
                  : "Calculated at next step"}
              </LatoBold>
            )}
          </View>
        </View>
      )}
      {Boolean(!isCheckout) && (
        <TouchableOpacity
          onPress={() => {
            if (window.fbq) {
              window.fbq('track', 'InitiateCheckout');
            };
            navigate("/checkout/address");
          }}
          style={{
            backgroundColor: colors.grey33,
            borderRadius: 4,
            paddingVertical: 14,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CgShoppingBag color={colors.whiteFF} size={20} />
          <LatoRegular
            style={{
              fontSize: 16,
              lineHeight: 24,
              color: colors.whiteFF,
              textAlign: "center",
              marginLeft: 8,
            }}
          >
            Continue Checkout
          </LatoRegular>
        </TouchableOpacity>
      )}
    </View>
  );
}

export default CheckoutProduct;
